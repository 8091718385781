import '@fontsource/libre-franklin/400.css'
import '@fontsource/libre-franklin/700.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/600.css'

export const bodyFont = `'Libre Franklin', sans-serif`
export const headingsFont = `'Poppins', sans-serif`

export const breakpoint = `@media (min-width: 999px)`
export const mobileBreakpoint = `@media (max-width: 768px)`
