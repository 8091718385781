import Footer from '@/components/layouts/Footer'
import Header from '@/components/layouts/Header'
import { Container } from '@/components/styled/Container'
import { bodyFont, headingsFont } from '@/utils/styles'
import { css, Global } from '@emotion/react'
import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'

export default function Layout({
  header,
  LayoutContainer = Container,
  children
}: {
  header?: ReactNode
  LayoutContainer?: any
  children: ReactNode
}) {
  return (
    <>
      <Global
        styles={css`
          html,
          body {
            margin: 0;
            padding: 0;
            font-family: ${bodyFont};
            color: #020247;
            background-color: #fafaff;
            line-height: 1.5;
          }

          * {
            box-sizing: border-box;
          }

          *:focus {
            outline: none;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-family: ${headingsFont};
          }

          input,
          select,
          textarea {
            font: inherit;
          }

          input[type='submit'],
          button {
            cursor: pointer;
          }
        `}
      />
      <Helmet>
        <title>Ichigo Inc.</title>
      </Helmet>
      {header}
      <LayoutContainer>{children}</LayoutContainer>
      <Footer />
    </>
  )
}

Layout.defaultProps = {
  header: <Header />,
  LayoutContainer: Container
}
