import logo from '@/assets/images/logo-footer.svg'
import japanhaul from '@/assets/images/other-services/japanhaul.svg'
import nomakenolife from '@/assets/images/other-services/nomakenolife.svg'
import sakuraco from '@/assets/images/other-services/sakuraco.svg'
import tokyocatch from '@/assets/images/other-services/tokyocatch.svg'
import tokyotreat from '@/assets/images/other-services/tokyotreat.svg'
import yumetwins from '@/assets/images/other-services/yumetwins.svg'
import { Container } from '@/components/styled/Container'
import { breakpoint, headingsFont } from '@/utils/styles'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

const Section = styled.div`
  background-color: #1e14af;
  padding: 4rem 0;
  color: white;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${breakpoint} {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    gap: 4rem;
  }
`

const Logo = styled.img`
  width: 88px;
  margin-left: 0.75rem;

  ${breakpoint} {
    margin-left: 0;
  }
`

const Menu = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  margin-bottom: 1rem;

  ${breakpoint} {
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 0;
  }
`

const MenuItem = styled(Link)`
  display: block;
  padding: 0.5rem 1rem;
  color: white;
  text-decoration: none;
  font-family: ${headingsFont};
`

const RightFooter = styled.div`
  flex: 1;
`

const OtherServices = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 1.5rem;
  padding-top: 2rem;

  ${breakpoint} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const OtherService = styled.img``

const Copyright = styled.p`
  margin-top: 3rem;
  text-align: center;

  ${breakpoint} {
    text-align: right;
  }
`

export default function Footer() {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Link to="/">
            <Logo src={logo} alt="ICHIGO Inc" />
          </Link>
          <Menu>
            <div>
              <MenuItem to="/services">Services</MenuItem>
              <MenuItem to="/news">News</MenuItem>
              <MenuItem to="/team">Team</MenuItem>
              <MenuItem to="https://www.wantedly.com/companies/ichigo-inc">
                Recruit
              </MenuItem>
              <MenuItem to="/contact">Contact</MenuItem>
            </div>
          </Menu>
          <RightFooter>
            <OtherServices>
              <Link to="https://yumetwins.com" target="_blank">
                <OtherService src={yumetwins} style={{ width: 107 }} />
              </Link>
              <Link to="https://tokyotreat.com" target="_blank">
                <OtherService src={tokyotreat} style={{ width: 145 }} />
              </Link>
              <Link to="https://tokyocatch.com" target="_blank">
                <OtherService src={tokyocatch} style={{ width: 128 }} />
              </Link>
              <Link to="https://nomakenolife.com" target="_blank">
                <OtherService src={nomakenolife} style={{ width: 114 }} />
              </Link>
              <Link to="https://sakura.co" target="_blank">
                <OtherService src={sakuraco} style={{ width: 143 }} />
              </Link>
              <Link to="https://japanhaul.com" target="_blank">
                <OtherService src={japanhaul} style={{ width: 111 }} />
              </Link>
            </OtherServices>
            <Copyright>© 2022 Ichigo. All rights reserved.</Copyright>
          </RightFooter>
        </Wrapper>
      </Container>
    </Section>
  )
}
