import { breakpoint } from '@/utils/styles'
import styled from '@emotion/styled'

export const H1 = styled.h1`
  text-align: center;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 2rem;

  ${breakpoint} {
    font-size: 44px;
    line-height: 52px;
    margin-bottom: 4rem;
  }
`

export const H2 = styled.h2`
  font-size: 24px;
  line-height: 36px;

  ${breakpoint} {
    font-size: 36px;
    line-height: 44px;
  }
`

export const H3 = styled.h3`
  font-size: 24px;
  line-height: 36px;
`

export const H4 = styled.h4`
  font-size: 16px;
  line-height: 24px;
`

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 32px;
`

export const LongFormContent = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 5rem;

  a {
    color: #ff0030;
  }

  li {
    margin-bottom: 0.75rem;
  }

  ${breakpoint} {
    font-size: 18px;
  }
`
