import { breakpoint } from '@/utils/styles'
import styled from '@emotion/styled'

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
  overflow: hidden;

  ${breakpoint} {
    padding: 0 2rem;
  }
`

export const ContainerNarrow = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  ${breakpoint} {
    padding: 0 2rem;
  }
`

export const ContainerFullWidth = styled.div``
