import logo from '@/assets/images/logo-header.svg'
import { Container } from '@/components/styled/Container'
import { breakpoint, mobileBreakpoint, headingsFont } from '@/utils/styles'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoint} {
    flex-direction: row;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`

const Logo = styled.img`
  width: 160px;
  margin-top: 1rem;

  ${breakpoint} {
    width: 218px;
    margin: 0;
  }
`

const Menu = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: center;
  z-index: 1;

  ${breakpoint} {
    margin-top: 1rem;
    min-height: 87px;
    margin-left: auto;
    z-index: 1;
  }
`

const MenuItem = styled(Link)<{ inverse: boolean }>`
  color: ${({ inverse }) => (inverse ? `white` : `#020247`)};
  font-family: ${headingsFont};
  text-decoration: none;
  padding: 1rem 0.75rem 0;
  font-size: 14px;

  &.active {
    color: #ff0030;
    font-weight: 600;
  }

  ${breakpoint} {
    font-size: 22px;
    padding: 1rem 1rem;
  }
`

const BreakPoint = styled.div`
  ${mobileBreakpoint} {
    width: 100%;
  }
`

export default function Header({
  hasLogo,
  inverse
}: {
  hasLogo?: boolean
  inverse?: boolean
}) {
  return (
    <Wrapper>
      <Link to="/">{hasLogo && <Logo src={logo} />}</Link>

      <Menu>
        <MenuItem inverse={inverse} activeClassName="active" to="/">
          Home
        </MenuItem>
        <MenuItem inverse={inverse} activeClassName="active" to="/services">
          Services
        </MenuItem>
        <MenuItem inverse={inverse} activeClassName="active" to="/news">
          News
        </MenuItem>
        <MenuItem inverse={inverse} activeClassName="active" to="/team">
          Team
        </MenuItem>
        <BreakPoint />
        <MenuItem
          inverse={inverse}
          activeClassName="active"
          to="https://www.wantedly.com/companies/ichigo-inc"
        >
          Recruit
        </MenuItem>
        <MenuItem inverse={inverse} activeClassName="active" to="/contact">
          Contact
        </MenuItem>
      </Menu>
    </Wrapper>
  )
}

Header.defaultProps = {
  hasLogo: true,
  inverse: false
}
